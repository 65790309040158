exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-support-js": () => import("./../../../src/pages/en/support.js" /* webpackChunkName: "component---src-pages-en-support-js" */),
  "component---src-pages-en-terms-and-conditions-js": () => import("./../../../src/pages/en/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-en-terms-and-conditions-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy_policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/support.js" /* webpackChunkName: "component---src-templates-support-js" */),
  "component---src-templates-terms-and-conditions-js": () => import("./../../../src/templates/terms_and_conditions.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-js" */)
}

